import throttle from 'lodash/throttle';
import type { ComputedRef, Ref } from 'vue';
import { computed, onMounted, ref } from 'vue';

import type { AiCarouselItem } from '../types';

interface Options {
  currentStep: Ref<number>;
  minimum: number;
  container: Ref<HTMLElement | undefined>;
  animation?: number;
  gutter?: ComputedRef<number>;
  position?: 'full' | 'right' | 'left';
  slideWidthInPercent?: ComputedRef<number>;
  disableCarouselOnNotEnoughItems: Ref<boolean>;
}

interface InitialCarouselData {
  collection: Ref<AiCarouselItem[]>;
  middleTranslate: ComputedRef<number>;
  recomputeBuffer: () => void;
  slideBuffer: Ref<number[]>;
  translateTo: (
    translation: number,
    animate?: boolean,
    duration?: number,
  ) => void;
  slideWidth: Ref<number>;
  slidesCount: Ref<number>;
  disableCarouselBehaviour: ComputedRef<boolean>;
  refreshCollection: () => void;
}

/**
 * Ensure the carousel will have enough items to do an infinite scroll
 */
export function getIterableCollection(
  collection: AiCarouselItem[],
  minimum: number,
  disableCarouselBehaviour: Ref<boolean>,
): AiCarouselItem[] {
  const iterableCollection = Array.from(collection);

  if (!iterableCollection.length || disableCarouselBehaviour.value)
    return iterableCollection;

  if (iterableCollection.length < minimum) {
    const referenceCollection = Array.from(collection);
    const totalNumberOfItems = referenceCollection.length;
    let cursor = 0;

    while (iterableCollection.length < minimum) {
      iterableCollection.push(referenceCollection[cursor]);
      iterableCollection.unshift(
        referenceCollection[totalNumberOfItems - cursor - 1],
      );

      cursor++;

      if (cursor >= totalNumberOfItems) {
        cursor = 0;
      }
    }
  }

  return iterableCollection.map((item, index) => {
    if (item.key) {
      return {
        ...item,
        key: item.key + '#' + index,
      };
    }

    return {
      ...item,
      key: Math.floor(Math.random() * 1000000).toString(),
    };
  });
}

/**
 * Get the slide buffer (array of index for the slides position)
 * Allow to compute the order of the slides (flex) and translations
 * @see getSlideBuffer
 */
export function computeBuffer(
  nbOfSlides: number,
  step: number,
  disableCarouselBehaviour: Ref<boolean>,
  originalSlidesCount: number,
): number[] {
  if (!nbOfSlides) return [];

  const buffer = [...Array(nbOfSlides).keys()].map(i => i + 1);

  if (disableCarouselBehaviour.value) return buffer;

  const middle = getMiddleIndex(nbOfSlides, originalSlidesCount);

  while (buffer[middle] !== step) {
    buffer.unshift(buffer.pop() as number);
  }

  return buffer;
}

/**
 * Update the slide width based on element painted + configuration
 * @param carouselElement
 * @param slideWidth
 * @param param2
 * @returns
 */
function updateSlideWidth(
  carouselElement: Ref<HTMLElement | undefined>,
  slideWidth: Ref<number>,
  {
    slideWidthInPercent,
    gutter,
  }: {
    slideWidthInPercent: Ref<number> | undefined;
    gutter: Ref<number> | undefined;
  },
) {
  if (!carouselElement.value) return;

  const slideRect =
    carouselElement.value.parentElement?.getBoundingClientRect();

  const carouselWidth = slideRect?.width ?? 0;
  const slideFactor = slideWidthInPercent?.value ?? 0;
  const gutterOffset = gutter?.value ?? 0;
  const numberOfGutterInCarouselViewport = Math.round(1 / slideFactor) - 1;
  const numberOfSlideInCarouselViewport = Math.round(1 / slideFactor);

  const gutterRatioInViewport =
    numberOfGutterInCarouselViewport / numberOfSlideInCarouselViewport;

  slideWidth.value =
    carouselWidth * slideFactor -
    gutterOffset * gutterRatioInViewport +
    gutterOffset;
}

function updateMiddleTranslateForFull(
  previousSlidesWidth: number,
  position: 'full' | 'right' | 'left' | undefined,
  carousel: HTMLElement | undefined,
  slideFactor: number,
): number {
  if (position !== 'full' || slideFactor >= 1 || !carousel)
    return previousSlidesWidth;

  const carouselWidth = carousel.getBoundingClientRect()?.width ?? 0;

  const remainingSpace = (1 - slideFactor) * carouselWidth;

  return previousSlidesWidth - remainingSpace / 2;
}

function getMiddleIndex(slidesCount: number, collectionLength: number): number {
  const potentialMiddle = Math.floor(slidesCount / collectionLength);

  if (slidesCount === collectionLength) {
    return Math.floor(slidesCount / 2);
  } else if (potentialMiddle % collectionLength === 0) {
    return potentialMiddle;
  } else {
    return (
      potentialMiddle +
      (collectionLength - (potentialMiddle % collectionLength))
    );
  }
}

// Warning : require a lots of Vue hook + requestAnimationFrame for performance reasons
export function useInitialCarouselData(
  collection: Ref<AiCarouselItem[]>,
  {
    currentStep,
    minimum,
    container,
    animation,
    gutter,
    position,
    slideWidthInPercent,
    disableCarouselOnNotEnoughItems,
  }: Options,
): InitialCarouselData {
  const disableCarouselBehaviour = computed(() =>
    Boolean(
      disableCarouselOnNotEnoughItems.value &&
        collection.value.length * (slideWidthInPercent?.value ?? 0) <= 1,
    ),
  );

  const iterableCollection = shallowRef(
    getIterableCollection(collection.value, minimum, disableCarouselBehaviour),
  );
  const slidesCount = computed(() => iterableCollection.value.length);
  const slideWidth = ref<number>(0);
  const slideBuffer = ref<number[]>(
    computeBuffer(
      slidesCount.value,
      currentStep.value,
      disableCarouselBehaviour,
      collection.value.length,
    ),
  );

  const middleTranslate = computed<number>(() => {
    if (disableCarouselBehaviour.value) return 0;

    const middle = getMiddleIndex(slidesCount.value, collection.value.length);

    const slideFactor = slideWidthInPercent?.value ?? 0;
    const width = slideWidth.value;

    let previousSlidesWidth = middle * width;

    previousSlidesWidth = updateMiddleTranslateForFull(
      previousSlidesWidth,
      position,
      container.value,
      slideFactor,
    );

    return previousSlidesWidth * -1;
  });

  onMounted(() => {
    nextTick(() => {
      updateSlideWidth(container, slideWidth, {
        gutter,
        slideWidthInPercent,
      });

      window.addEventListener('resize', onWindowResize, { passive: true });

      translateTo(middleTranslate.value);
    });
  });

  onBeforeUnmount(() => {
    nextTick(() => {
      window.removeEventListener('resize', onWindowResize, { capture: true });
    });
  });

  // Helpers function to help recenter/shuffle the carousel
  const recomputeBuffer = (): void => {
    requestAnimationFrame(() => {
      slideBuffer.value = computeBuffer(
        slidesCount.value,
        currentStep.value,
        disableCarouselBehaviour,
        collection.value.length,
      );
    });
  };

  const translateTo = (
    translation: number,
    animate = false,
    duration: number | null = null,
  ) => {
    if (!container.value) return;

    const transform = `translateX(${Math.round(translation)}px)`;
    const transition = animate
      ? `transform ${duration ?? animation ?? 300}ms`
      : 'none';

    container.value.style.transform = transform;
    container.value.style.transition = transition;
  };

  const refreshCollection = () => {
    iterableCollection.value = getIterableCollection(
      collection.value,
      minimum,
      disableCarouselBehaviour,
    );
    recomputeBuffer();
    translateTo(middleTranslate.value);
  };

  // ------------------------------
  // Internal not exposed functions

  const onWindowResize = throttle(
    () => {
      nextTick(() => {
        updateSlideWidth(container, slideWidth, {
          gutter,
          slideWidthInPercent,
        });
        recomputeBuffer();
        translateTo(middleTranslate.value);

        iterableCollection.value = getIterableCollection(
          collection.value,
          minimum,
          disableCarouselBehaviour,
        );
      });
    },
    16,
    { leading: true, trailing: true },
  );

  if (gutter) {
    watch(gutter, onWindowResize);
  }

  if (slideWidthInPercent) {
    watch(slideWidthInPercent, onWindowResize);
  }

  // Return value
  return {
    collection: iterableCollection,
    disableCarouselBehaviour,
    middleTranslate,
    recomputeBuffer,
    slideBuffer,
    slideWidth,
    slidesCount,
    translateTo,
    refreshCollection,
  };
}
